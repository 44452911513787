import { AppButton, AppInput, AppModal, AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { useForm } from 'react-hook-form';

const AdvisorRequestList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const titles = ['İSİM', 'E-POSTA', 'DOKTOR', 'TARİH', 'NOT', 'DURUM'];
  const status = {
    pending: 'Beklemede',
    approved: 'Onaylandı',
    rejected: 'Reddedildi',
    completed: 'Tamamlandı'
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const onDelete = async id => {
    api.shared.delete('advisor', id).then(() => {
      navigate(0);
    });
  };

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {}
  });

  const onAction = async (row, type) => {
    setSelectedRow({ ...row, type });
    setIsModalOpen(true);
  };

  const onSubmit = async submitData => {
    setIsLoading(true);

    const params = {
      status: selectedRow.type
    };

    if (selectedRow.type !== 'approved') {
      params.advisorNote = submitData.advisorNote;
    }

    await api.shared
      .update('advisor', params, selectedRow._id)
      .then(async () => {
        navigate(0);
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  useEffect(() => {
    api.shared
      .list('advisor', page)
      .then(async res => {
        const data = await res.data.map(item => {
          return {
            _id: item._id,
            name: item.userId.name,
            email: item.userId.email,
            doctor: item.doctorId.name,
            date: item.date,
            note: item.note || 'Not yok.',
            status: item.status,
            statusText: status[item.status]
          };
        });
        setData(data);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <AppTable
        titles={titles}
        rows={data}
        isEdit={false}
        isDelete={true}
        isAction={true}
        isComplete={true}
        pageKey="user"
        pageNumber={page}
        onDelete={onDelete}
        onAction={onAction}
        onComplete={onAction}
        isSendArchive={false}
        pageItems={20}
        hideRows="__v,_id,updatedAt,type,status"
      />
      <AppModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className={`text-center text-2xl font-bold mb-4`}>
          {selectedRow.type === 'approved'
            ? 'Talep Onayla'
            : selectedRow.type === 'rejected'
            ? 'Talep Reddet'
            : 'Talep Tamamla'}
        </div>
        <div className="text-center mb-4">
          {selectedRow.name} adlı kullanıcının talebini{' '}
          {selectedRow.type === 'approved'
            ? 'onaylamak'
            : selectedRow.type === 'rejected'
            ? 'reddetmek'
            : 'tamamlamak'}{' '}
          istediğinize emin misiniz?
        </div>
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          {selectedRow.type !== 'completed' && (
            <AppInput
              label="Not"
              name="advisorNote"
              // @ts-ignore
              error={errors.advisorNote}
              rules={{}}
              register={register}
              placeholder="Not"
            />
          )}
          <AppButton type="submit" className="mt-5" isLoading={isLoading}>
            {selectedRow.type === 'approved'
              ? 'Onayla'
              : selectedRow.type === 'rejected'
              ? 'Reddet'
              : 'Tamamla'}
          </AppButton>
        </form>
      </AppModal>
    </main>
  );
};

export default AdvisorRequestList;
