import { AppButton, AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';

const CityList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const titles = ['ŞEHİR', 'ÜLKE'];

  const onDelete = async id => {
    api.shared.delete('city', id).then(() => {
      navigate(0);
    });
  };

  useEffect(() => {
    api.shared
      .list('city', page)
      .then(async res => {
        setData(res.data);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <AppButton
        isAdd={true}
        className="mb-5 ml-auto"
        isFullWidth={false}
        onClick={() => navigate('/city/add')}
      >
        Yeni Şehir Ekle
      </AppButton>
      <AppTable
        titles={titles}
        rows={data}
        isEdit={true}
        isDelete={true}
        pageKey="city"
        pageNumber={page}
        onDelete={onDelete}
        pageItems={20}
        hideRows="__v,_id,createdAt,updatedAt,slug"
      />
    </main>
  );
};

export default CityList;
