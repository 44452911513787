import React from 'react';
import { AppHeader, AppSidebar } from 'components';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import menu from 'constants/menu';

const DashboardLayout = () => {
  const location = useLocation();
  let page = {
    isVisible: true,
    name: 'Anasayfa',
    path: '/',
    mainPath: '/'
  };
  menu.map(item => {
    if (item.children) {
      const getPage = item.children.find(
        child => child.path === location.pathname
      );
      if (getPage) {
        page = getPage;
      }
    }
  });

  const PrivateRoute = ({ children }) => {
    const { user } = useAuth();

    if (!user) {
      return <Navigate to="/auth/login" replace={true} />;
    }

    return children;
  };

  return (
    <PrivateRoute>
      <div className="h-screen flex bg-gray-100">
        <div className="flex w-1/6 px-4 py-10 bg-gray-100 border-gray-900">
          <AppSidebar activePage={page} />
        </div>
        <div className="flex flex-col w-5/6 px-8 pt-5 pb-10 max-h-screen overflow-y-scroll bg-white my-5 mr-5 shadow-lg rounded-xl no-scrollbar">
          <AppHeader pageName={page?.name} />
          <Outlet />
        </div>
      </div>
    </PrivateRoute>
  );
};

export default DashboardLayout;
