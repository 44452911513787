import React from 'react';
import { useAuth } from 'context/AuthContext';

const AppHeader = ({ pageName }) => {
  const { user } = useAuth();
  const date = new Date();
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const dayName = date.toLocaleString('default', { weekday: 'long' });
  const hour = date.getHours();
  const dayPart =
    hour < 12 ? 'Günaydın' : hour < 18 ? 'İyi Günler' : 'İyi Akşamlar';

  return (
    <div className="flex justify-between items-center mb-10">
      <div className="flex flex-col gap-1">
        <span className={'text-sm text-gray-600'}>
          {day} {month}, {dayName}
        </span>
        <span className="font-medium text-gray-800 text-xl">
          {dayPart}, {user.name}
        </span>
      </div>
      <div className="font-medium text-gray-600 border px-4 py-2 rounded-xl shadow">
        {pageName}
      </div>
    </div>
  );
};

export default AppHeader;
