import React from 'react';
import Modal from 'react-modal';

const AppModal = ({ isOpen, onClose, children }) => {
  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      top: 300,
      left: '50%',
      right: 'auto',
      width: '50%',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '12px',
      border: 'none',
      padding: '20px',
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.15)',
      maxWidth: '600px'
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      {children}
    </Modal>
  );
};

export default AppModal;
