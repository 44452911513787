import { HomeOutlined } from '@mui/icons-material';
import {
  IconCategory,
  IconCheckupList,
  IconCreditCard,
  IconMapPin,
  IconStethoscope,
  IconUsers,
  IconVideo,
  IconWorld
} from '@tabler/icons-react';

export default [
  {
    name: 'Anasayfa',
    Icon: HomeOutlined,
    path: '/',
    mainPath: '/',
    isVisible: false
  },
  {
    name: 'Tanımlamalar',
    isVisible: true,
    children: [
      {
        name: 'Ülkeler',
        Icon: IconWorld,
        path: '/country/list',
        mainPath: '/country/list',
        isVisible: true
      },
      {
        name: 'Ülke Ekle',
        Icon: IconWorld,
        path: '/country/add',
        mainPath: '/country/list',
        isVisible: false
      },
      {
        name: 'Şehirler',
        Icon: IconMapPin,
        path: '/city/list',
        mainPath: '/city/list',
        isVisible: true
      },
      {
        name: 'Şehir Ekle',
        Icon: IconMapPin,
        path: '/city/add',
        mainPath: '/city/add',
        isVisible: false
      },
      {
        name: 'Kategoriler',
        Icon: IconCategory,
        path: '/category/list',
        mainPath: '/category/list',
        isVisible: true
      },
      {
        name: 'Kategori Ekle',
        Icon: IconCategory,
        path: '/category/add',
        mainPath: '/category/list',
        isVisible: false
      },
      {
        name: 'Alt Kategoriler',
        Icon: IconCategory,
        path: '/sub-category/list',
        mainPath: '/category/list',
        isVisible: false
      },
      {
        name: 'Alt Kategori Ekle',
        Icon: IconCategory,
        path: '/sub-category/add',
        mainPath: '/category/list',
        isVisible: false
      },
      {
        name: 'Doktorlar',
        Icon: IconStethoscope,
        path: '/doctor/list',
        mainPath: '/doctor/list',
        isVisible: true
      },
      {
        name: 'Doktor Ekle',
        Icon: IconStethoscope,
        path: '/doctor/add',
        mainPath: '/doctor/list',
        isVisible: false
      },
      {
        name: 'Doktor Müsait Saatleri',
        Icon: IconStethoscope,
        path: '/doctor/available-dates',
        mainPath: '/doctor/list',
        isVisible: false
      },
      {
        name: 'Doktor Fotoğrafları',
        Icon: IconStethoscope,
        path: '/doctor/photos',
        mainPath: '/doctor/list',
        isVisible: false
      }
    ]
  },
  {
    name: 'Kullanıcı İşlemleri',
    isVisible: true,
    children: [
      {
        name: 'Kullanıcılar',
        Icon: IconUsers,
        path: '/user/list',
        mainPath: '/user/list',
        isVisible: true
      },
      {
        name: 'Görüşme Talepleri',
        Icon: IconVideo,
        path: '/meeting/list',
        mainPath: '/meeting/list',
        isVisible: true
      },
      {
        name: 'Danışman Talepleri',
        Icon: IconCheckupList,
        path: '/advisor-request/list',
        mainPath: '/advisor-request/list',
        isVisible: true
      },
      {
        name: 'Ödemeler',
        Icon: IconCreditCard,
        path: '/payment/list',
        mainPath: '/payment/list',
        isVisible: true
      },
      {
        name: 'Ödeme Ekle',
        Icon: IconCreditCard,
        path: '/payment/add',
        mainPath: '/payment/list',
        isVisible: false
      }
    ]
  }
];
