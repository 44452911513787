import React, { useEffect, useMemo } from 'react';
import { AppButton, AppInput } from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { t } from 'locales';
import api from 'services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { convertToSlug } from '../../utils/slug';

export default function AddLeague() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const id = searchParams.get('id');
  const isEdit = !!id;

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return data;
    }, [data])
  });

  useEffect(() => {
    const init = async () => {
      if (isEdit) {
        await api.shared.get('country', id).then(async res => {
          const data = res.data;
          const editData = {
            name: data.name[0].value,
            code: data.code
          };
          setData(editData);
          reset(editData);
        });
      }
    };
    init();
  }, []);

  const onSubmit = async submitData => {
    setIsLoading(true);
    const params = {
      name: {
        lang: 'en',
        value: submitData.name
      },
      slug: convertToSlug(submitData.name),
      code: submitData.code
    };
    await api.shared[isEdit ? 'update' : 'create']('country', params, id)
      .then(async () => {
        navigate('/country/list');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-2xl font-bold text-gray-700 mb-2">
        Yeni Ülke Ekle
      </div>
      <div className="text-gray-700 mb-8 text-center">
        Yeni ülke eklemek için aşağıdaki formu doldurun. Ülke kodu 2 karakter
        olmalıdır.
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label="Ülke Adı"
          name="name"
          // @ts-ignore
          error={errors.name}
          rules={{}}
          register={register}
          placeholder="Ülke Adı"
        />
        <AppInput
          label="Ülke Kodu"
          name="code"
          // @ts-ignore
          error={errors.code}
          rules={{}}
          register={register}
          placeholder="Ülke Kodu"
        />
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          {t('_general.save')}
        </AppButton>
      </form>
    </main>
  );
}
