import { AppButton, AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';

const DoctorList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const titles = ['İSİM', 'KATEGORİ', 'ŞEHİR'];

  const onDelete = async id => {
    api.shared.delete('doctor', id).then(() => {
      navigate(0);
    });
  };

  useEffect(() => {
    api.shared
      .list('doctor', page)
      .then(async res => {
        setData(res.data);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <AppButton
        className="mb-5 ml-auto"
        isFullWidth={false}
        isAdd={true}
        onClick={() => navigate('/doctor/add')}
      >
        Yeni Doktor Ekle
      </AppButton>
      <AppTable
        titles={titles}
        rows={data}
        isEdit={true}
        isDelete={true}
        pageKey="doctor"
        pageNumber={page}
        onDelete={onDelete}
        onCustomAction={(row, type) => {
          if (type === 'available-dates') {
            navigate(`/doctor/available-dates?id=${row._id}`);
          } else if (type === 'photos') {
            navigate(`/doctor/photos?id=${row._id}`);
          } else if (type === 'price-list') {
            navigate(`/doctor/price-list?id=${row._id}`);
          } else if (type === 'documents') {
            navigate(`/doctor/documents?id=${row._id}`);
          }
        }}
        customActions={[
          {
            title: 'Müsait Saatler',
            type: 'available-dates'
          },
          {
            title: 'Fotoğraflar',
            type: 'photos'
          },
          {
            title: 'Fiyat Listesi',
            type: 'price-list'
          },
          {
            title: 'Belgeler',
            type: 'documents'
          }
        ]}
        isSendArchive={false}
        pageItems={20}
        hideRows="__v,_id,createdAt,updatedAt,content,availableTimes,images,cv,subCategory,country,age,nationality,about,isDeleted,averagePriceList,profileImage,isMostPreferred,isMostCalled,slug,description,rating,email,password"
      />
    </main>
  );
};

export default DoctorList;
