import { AppButton, AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';

const SubCategoryList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const titles = ['ALT KATEGORİ İSMİ', 'ANA KATEGORİ'];

  const onDelete = async id => {
    api.shared.delete('subCategory', id).then(() => {
      navigate(0);
    });
  };

  useEffect(() => {
    api.shared
      .list('subCategory', page)
      .then(async res => {
        setData(res.data);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <AppButton
        isAdd={true}
        className="mb-5 ml-auto"
        isFullWidth={false}
        onClick={() => navigate('/sub-category/add')}
      >
        Yeni Alt Kategori Ekle
      </AppButton>
      <AppTable
        titles={titles}
        rows={data}
        isEdit={true}
        isDelete={true}
        pageKey="sub-category"
        pageNumber={page}
        onDelete={onDelete}
        pageItems={10}
        hideRows="__v,_id,createdAt,updatedAt,slug,photoDescription,photoLength"
      />
    </main>
  );
};

export default SubCategoryList;
