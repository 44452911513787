import { AppButton, AppModal, AppTable, MaskInput } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { useForm } from 'react-hook-form';

const DoctorList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [data, setData] = useState([]);
  const [page] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const titles = ['DOCTOR', 'TARİH', 'DOLU MU?'];
  const [doctor, setDoctor] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onDelete = async id => {
    api.shared.delete('availableDate', id).then(() => {
      navigate(0);
    });
  };

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {}
  });

  const onSubmit = async submitData => {
    setIsLoading(true);
    const splitDate = submitData.date.split('/');
    const dateFormat = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
    const meetingDate = new Date(`${dateFormat} ${submitData.time}`);

    await api.shared
      .create('availableDate', {
        doctorId: id,
        date: meetingDate
      })
      .then(async () => {
        navigate(0);
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  useEffect(() => {
    api.shared
      .listByDoctor('availableDate', id)
      .then(async res => {
        setData(res.data);
      })
      .catch(error => console.log('error', error));

    api.shared
      .get('doctor', id)
      .then(async res => {
        setDoctor(res.data);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <AppButton
        className="mb-5 ml-auto"
        isFullWidth={false}
        isAdd={true}
        onClick={() => setIsModalOpen(true)}
      >
        Yeni Tarih Ekle
      </AppButton>
      <AppTable
        titles={titles}
        rows={data}
        isEdit={false}
        isDelete={true}
        pageKey="doctor"
        pageNumber={page}
        onDelete={onDelete}
        isSendArchive={false}
        pageItems={20}
        hideRows="__v,_id,createdAt,updatedAt,isDeleted"
      />
      <AppModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className={`text-center text-2xl font-bold mb-4`}>
          Yeni Tarih Ekle
        </div>
        <div className="flex text-sm text-gray-700 mb-2 gap-2">
          Doktor:
          <span className={`font-bold`}>{doctor.name}</span>
        </div>
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <MaskInput
            label="Görüşme Tarihi"
            mask="99/99/9999"
            maskPlaceholder="GG/AA/YYYY"
            name="date"
            // @ts-ignore
            error={errors.date}
            register={register}
            placeholder="Görüşme Tarihi"
          />
          <MaskInput
            label="Görüşme Saati"
            mask="99:99"
            maskPlaceholder="HH:MM"
            name="time"
            // @ts-ignore
            error={errors.time}
            register={register}
            placeholder="Görüşme Saati"
          />
          <AppButton type="submit" className="mt-5" isLoading={isLoading}>
            EKLE
          </AppButton>
        </form>
      </AppModal>
    </main>
  );
};

export default DoctorList;
