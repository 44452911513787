import React, { useEffect, useMemo } from 'react';
import { AppButton, AppInput, AppSelect } from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { t } from 'locales';
import api from 'services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { convertToSlug } from '../../utils/slug';

export default function AddSubCategory() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [categories, setCategories] = useState([]);
  const id = searchParams.get('id');
  const isEdit = !!id;

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return data;
    }, [data])
  });

  useEffect(() => {
    const init = async () => {
      const categoriesData = [];
      await api.shared.list('category', 0).then(async res => {
        res.data.map(category => {
          categoriesData.push({
            value: category._id,
            label: category.name[0].value
          });
        });
        setCategories(categoriesData);
      });
      if (isEdit) {
        await api.shared.get('subCategory', id).then(async res => {
          const data = res.data;
          const editData = {
            name: data.name[0].value,
            category: categoriesData.find(item => item.value === data.category),
            photoLength: data.photoLength,
            photoDescription: data.photoDescription[0].value
          };
          setData(editData);
          reset(editData);
        });
      }
    };
    init();
  }, []);

  const onSubmit = async submitData => {
    setIsLoading(true);
    const params = {
      category: submitData.category.value,
      name: {
        lang: 'en',
        value: submitData.name
      },
      slug: convertToSlug(submitData.name),
      photoDescription: {
        lang: 'en',
        value: submitData.photoDescription
      },
      photoLength: submitData.photoLength
    };
    await api.shared[isEdit ? 'update' : 'create']('subCategory', params, id)
      .then(async () => {
        navigate('/sub-category/list');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-2xl font-bold text-gray-700 mb-2">
        Yeni Alt Kategori Ekle
      </div>
      <div className="text-gray-700 mb-8 text-center">
        Bu alanda yeni alt kategori ekleyebilirsiniz. Alt kategori eklerken
        kategori seçmeyi unutmayın.
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppSelect
          options={categories}
          label="Kategori"
          name="category"
          error={errors.league}
          rules={{ required: true }}
          control={control}
          placeholder="Kategori"
        />
        <AppInput
          label="Alt Kategori Adı"
          name="name"
          // @ts-ignore
          error={errors.name}
          rules={{}}
          register={register}
          placeholder="Alt Kategori Adı"
        />
        <AppInput
          label="Fiyat Al - Fotoğraf Adedi"
          name="photoLength"
          // @ts-ignore
          error={errors.photoLength}
          rules={{ number: true, min: 1 }}
          register={register}
          placeholder="Fotoğraf Adedi"
        />
        <AppInput
          label="Fiyat Al - Fotoğraf Açıklaması"
          name="photoDescription"
          // @ts-ignore
          error={errors.photoDescription}
          rules={{}}
          register={register}
          placeholder="Fotoğraf Açıklaması"
        />
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          {t('_general.save')}
        </AppButton>
      </form>
    </main>
  );
}
