import { AppButton, AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';

const AdvisorRequestList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const titles = [
    'İSİM',
    'E-POSTA',
    'DOKTOR',
    'TARİH',
    'ÖDEME ID',
    'TUTAR',
    'DURUM'
  ];

  const status = {
    pending: 'Beklemede',
    completed: 'Tamamlandı'
  };

  const onDelete = async id => {
    api.shared.delete('payment', id).then(() => {
      navigate(0);
    });
  };

  useEffect(() => {
    api.shared
      .list('payment', page)
      .then(async res => {
        const data = await res.data.map(item => {
          return {
            _id: item._id,
            name: item.user.name,
            email: item.user.email,
            doctor: item.doctor.name,
            date: item.date,
            paymentId: item.paymentId,
            price: item.price + ' Euro',
            status: item.status,
            statusText: status[item.status]
          };
        });
        setData(data);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <AppButton
        isAdd={true}
        className="mb-5 ml-auto"
        isFullWidth={false}
        onClick={() => navigate('/payment/add')}
      >
        Yeni Ödeme Ekle
      </AppButton>
      <AppTable
        titles={titles}
        rows={data}
        isEdit={true}
        isDelete={true}
        isAction={false}
        isComplete={false}
        pageKey="payment"
        pageNumber={page}
        onDelete={onDelete}
        isSendArchive={false}
        pageItems={20}
        hideRows="__v,_id,updatedAt,type,status"
      />
    </main>
  );
};

export default AdvisorRequestList;
