import { AppButton, AppInput, AppModal, AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { useForm } from 'react-hook-form';

const MeetingList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const titles = ['İSİM', 'E-POSTA', 'DOKTOR', 'TARİH', 'NOT', 'DURUM', 'ID'];
  const status = {
    pending: 'Beklemede',
    approved: 'Onaylandı',
    rejected: 'Reddedildi'
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const onDelete = async id => {
    api.shared.delete('meeting', id).then(() => {
      navigate(0);
    });
  };

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {}
  });

  const onAction = async (meeting, type) => {
    setSelectedMeeting({ ...meeting, type });
    setIsModalOpen(true);
  };

  const onSubmit = async submitData => {
    setIsLoading(true);
    const params = {};

    if (selectedMeeting.type === 'approved') {
      params.meetingUrl = submitData.meetingUrl;
      params.status = 'approved';
    } else {
      params.status = 'rejected';
      params.note = submitData.note;
    }
    await api.shared
      .update('meeting', params, selectedMeeting._id)
      .then(async () => {
        navigate(0);
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  useEffect(() => {
    api.shared
      .list('meeting', page)
      .then(async res => {
        const data = await res.data.map(item => {
          return {
            _id: item._id,
            name: item.userId.name,
            email: item.userId.email,
            doctor: item.doctorId.name,
            date: item.availableDateId.date,
            note: item.note || 'Not yok.',
            status: status[item.status],
            meetingId: item.meetingId
          };
        });
        setData(data);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <AppTable
        titles={titles}
        rows={data}
        isEdit={false}
        isDelete={true}
        isAction={true}
        pageKey="user"
        pageNumber={page}
        onDelete={onDelete}
        onAction={onAction}
        isSendArchive={false}
        pageItems={20}
        hideRows="__v,_id,updatedAt,type"
      />
      <AppModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className={`text-center text-2xl font-bold mb-4`}>
          {selectedMeeting.type === 'approved'
            ? 'Görüşme Onayla'
            : 'Görüşme Reddet'}
        </div>
        <div className="flex text-sm text-gray-700 mb-2 gap-2">
          Görüşme ID:
          <span className={`font-bold`}>{selectedMeeting.meetingId}</span>
        </div>
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          {selectedMeeting.type === 'rejected' ? (
            <AppInput
              label="Not"
              name="note"
              // @ts-ignore
              error={errors.note}
              rules={{}}
              register={register}
              placeholder="Not"
            />
          ) : (
            <AppInput
              label="Görüşme URL"
              name="meetingUrl"
              // @ts-ignore
              error={errors.meetingUrl}
              rules={{}}
              register={register}
              placeholder="Görüşme URL"
            />
          )}
          <AppButton type="submit" className="mt-5" isLoading={isLoading}>
            {selectedMeeting.type === 'approved' ? 'ONAYLA' : 'REDDET'}
          </AppButton>
        </form>
      </AppModal>
    </main>
  );
};

export default MeetingList;
