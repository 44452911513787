import React from 'react';
import menu from 'constants/menu';
import { Link } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import { IconHome, IconLogout, IconSettings } from '@tabler/icons-react';

const AppSidebar = ({ activePage }) => {
  const { user, setUser } = useAuth();

  const signOut = () => {
    setUser(false);
    localStorage.removeItem('user');
  };

  return (
    <div className="w-full flex flex-col justify-between">
      <h1 className={'font-bold text-xl w-full text-gray-700 ml-2'}>
        Surgeon Scanner
      </h1>
      <div className="flex flex-col w-full flex-1 mt-6">
        <Link
          to={'/'}
          className={`flex items-center text-gray-600 gap-3 hover:bg-white hover:shadow cursor-pointer p-2 rounded-xl hover:text-blue-600 transition-all ${
            activePage.mainPath === '/' ? 'bg-white shadow text-blue-600' : ''
          }`}
        >
          <IconHome size={24} stroke={1.5} color="currentColor" />
          <span className={'font-medium text-gray-600'}>Anasayfa</span>
        </Link>
        {menu.map((item, index) =>
          item.children && item.isVisible ? (
            <div key={index} className={'flex flex-col mt-6 gap-2'}>
              <div className="text-sm text-gray-400 ml-2">{item.name}</div>
              {item.children.map(
                (child, index) =>
                  child.isVisible && (
                    <Link
                      to={child.path}
                      key={index}
                      className={`flex items-center text-gray-600 gap-3 hover:bg-white hover:shadow cursor-pointer p-2 rounded-xl hover:text-blue-600 transition-all ${
                        activePage.mainPath === child.mainPath
                          ? 'bg-white shadow text-blue-600'
                          : ''
                      }`}
                    >
                      <child.Icon
                        size={24}
                        stroke={1.5}
                        color="currentColor"
                      ></child.Icon>
                      <span className="font-medium text-gray-600">
                        {child.name}
                      </span>
                    </Link>
                  )
              )}
            </div>
          ) : (
            <div key={index}></div>
          )
        )}
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex items-center text-gray-600 gap-3 hover:bg-white hover:shadow cursor-pointer p-2 rounded-xl hover:text-blue-600 transition-all">
          <IconSettings size={24} stroke={1.5} color="currentColor" />
          <span className={'font-medium text-gray-600'}>Ayarlar</span>
        </div>
        <div
          className="flex items-center text-gray-600 gap-3 hover:bg-white hover:shadow cursor-pointer p-2 rounded-xl hover:text-blue-600 transition-all"
          onClick={signOut}
        >
          <IconLogout size={24} stroke={1.5} color="currentColor" />
          <span className={'font-medium text-gray-600'}>Çıkış Yap</span>
        </div>
        <div className="flex items-center mt-4 text-gray-600 gap-3 hover:bg-white hover:shadow cursor-pointer p-2 rounded-xl hover:text-blue-600 transition-all">
          <img
            src={
              'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200'
            }
            className={'rounded-full w-8 h-8'}
          />
          <span className={'font-medium text-gray-600'}>{user.name}</span>
        </div>
      </div>
    </div>
  );
};

export default AppSidebar;
