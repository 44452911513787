import { AppButton, AppFile, AppInput, AppModal, AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { useForm } from 'react-hook-form';

const DoctorDocuments = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [data, setData] = useState([]);
  const [page] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const titles = ['DOCTOR', 'BAŞLIK', 'LOGO', 'BELGE'];
  const [doctor, setDoctor] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [logo, setLogo] = useState();
  const [document, setDocument] = useState();

  const onDelete = async id => {
    api.shared.delete('photo', id).then(() => {
      navigate(0);
    });
  };

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {}
  });

  const onSubmit = async submitData => {
    setIsLoading(true);
    await api.shared
      .create('document', {
        doctorId: id,
        title: {
          lang: 'en',
          value: submitData.title
        },
        document: document,
        logo: logo
      })
      .then(async () => {
        navigate(0);
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  useEffect(() => {
    api.shared
      .listByDoctor('document', id)
      .then(async res => {
        setData(res.data);
      })
      .catch(error => console.log('error', error));

    api.shared
      .get('doctor', id)
      .then(async res => {
        setDoctor(res.data);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <AppButton
        className="mb-5 ml-auto"
        isFullWidth={false}
        isAdd={true}
        onClick={() => setIsModalOpen(true)}
      >
        Yeni Belge Ekle
      </AppButton>
      <AppTable
        titles={titles}
        rows={data}
        isEdit={false}
        isDelete={true}
        pageKey="doctor"
        pageNumber={page}
        onDelete={onDelete}
        isSendArchive={false}
        pageItems={20}
        hideRows="__v,_id,createdAt,updatedAt,isDeleted"
      />
      <AppModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className={`text-center text-2xl font-bold mb-4`}>
          Yeni Belge Ekle
        </div>
        <div className="flex text-sm text-gray-700 mb-2 gap-2">
          Doktor:
          <span className={`font-bold`}>{doctor.name}</span>
        </div>
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <AppInput
            label="Fotoğraf Başlığı"
            name="title"
            // @ts-ignore
            error={errors.title}
            rules={{ required: true }}
            register={register}
            placeholder="Fotoğraf Başlığı"
          />
          <img src={logo} className="w-16 h-16 rounded-full mt-5 bg-gray-300" />
          <AppFile
            error=""
            label="Logo"
            onCompleted={file => setLogo(file.cdnUrl)}
            crop="auto"
          />
          {document && (
            <a
              href={document}
              target="_blank"
              rel="noreferrer"
              className="bg-blue-600 text-white px-3 py-2 text-center rounded font-medium mb-2 inline-flex"
            >
              Belgeyi Görüntüle
            </a>
          )}
          <AppFile
            error=""
            label="Belge"
            onCompleted={file => setDocument(file.cdnUrl)}
            crop="auto"
            imagesOnly={false}
          />
          <AppButton type="submit" className="mt-5" isLoading={isLoading}>
            EKLE
          </AppButton>
        </form>
      </AppModal>
    </main>
  );
};

export default DoctorDocuments;
