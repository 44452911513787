import { AppButton, AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { IconList } from '@tabler/icons-react';

const CategoryList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const titles = ['KATEGORİ İSMİ', 'URL'];

  const onDelete = async id => {
    api.shared.delete('category', id).then(() => {
      navigate(0);
    });
  };

  useEffect(() => {
    api.shared
      .list('category', page)
      .then(async res => {
        setData(res.data);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <div className={'flex flex-row justify-end mb-5 gap-3'}>
        <AppButton
          isAdd={true}
          isFullWidth={false}
          onClick={() => navigate('/category/add')}
        >
          Yeni Kategori Ekle
        </AppButton>
        <AppButton
          isFullWidth={false}
          onClick={() => navigate('/sub-category/list')}
        >
          <IconList size={20} strokeWidth={2} />
          Alt Kategoriler
        </AppButton>
      </div>
      <AppTable
        titles={titles}
        rows={data}
        isEdit={true}
        isDelete={true}
        pageKey="category"
        pageNumber={page}
        onDelete={onDelete}
        pageItems={20}
        hideRows="__v,_id,createdAt,updatedAt"
      />
    </main>
  );
};

export default CategoryList;
