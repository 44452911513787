export const sharedApi = api => ({
  create(type, params) {
    return api.post(`/shared/${type}/create`, params);
  },
  list(type, page) {
    return api.get(`/shared/${type}/list/${page}`);
  },
  get(type, id) {
    return api.get(`/shared/${type}/${id}`);
  },
  update(type, params, id) {
    return api.post(`/shared/${type}/update/${id}`, params);
  },
  delete(type, id) {
    return api.delete(`/shared/${type}/delete/${id}`);
  },
  listByDoctor(type, doctorId) {
    return api.get(`/shared/${type}/list-by-doctor/${doctorId}`);
  }
});
