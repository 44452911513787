import React from 'react';
import { AuthLayout, DashboardLayout } from '../layouts';
import Home from './Home';
import Login from './Login';
import Page404 from './Page404';
import AddCountry from './Countries/AddCountry';
import CountryList from './Countries/CountryList';
import AddCity from './City/AddCity';
import CityList from './City/CityList';
import CategoryList from './Category/CategoryList';
import AddCategory from './Category/AddCategory';
import SubCategoryList from './SubCategory/SubCategoryList';
import AddSubCategory from './SubCategory/AddSubCategory';
import AddDoctor from './Doctor/AddDoctor';
import DoctorList from './Doctor/DoctorList';
import UserList from './User/UserList';
import MeetingList from './Meeting/MeetingList';
import AdvisorRequestList from './AdvisorRequest/AdvisorRequestList';
import DoctorAvailableDates from './Doctor/DoctorAvailableDates';
import DoctorPhotos from './Doctor/DoctorPhotos';
import PaymentList from './Payment/PaymentList';
import AddPayment from './Payment/AddPayment';
import DoctorPriceList from './Doctor/DoctorPriceList';
import DoctorDocuments from './Doctor/DoctorDocuments';

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: 'country',
        children: [
          {
            path: 'list',
            element: <CountryList />
          },
          {
            path: 'add',
            element: <AddCountry />
          }
        ]
      },
      {
        path: 'city',
        children: [
          {
            path: 'list',
            element: <CityList />
          },
          {
            path: 'add',
            element: <AddCity />
          }
        ]
      },
      {
        path: 'category',
        children: [
          {
            path: 'list',
            element: <CategoryList />
          },
          {
            path: 'add',
            element: <AddCategory />
          }
        ]
      },
      {
        path: 'sub-category',
        children: [
          {
            path: 'list',
            element: <SubCategoryList />
          },
          {
            path: 'add',
            element: <AddSubCategory />
          }
        ]
      },
      {
        path: 'doctor',
        children: [
          {
            path: 'list',
            element: <DoctorList />
          },
          {
            path: 'add',
            element: <AddDoctor />
          },
          {
            path: 'available-dates',
            element: <DoctorAvailableDates />
          },
          {
            path: 'photos',
            element: <DoctorPhotos />
          },
          {
            path: 'price-list',
            element: <DoctorPriceList />
          },
          {
            path: 'documents',
            element: <DoctorDocuments />
          }
        ]
      },
      {
        path: 'payment',
        children: [
          {
            path: 'list',
            element: <PaymentList />
          },
          {
            path: 'add',
            element: <AddPayment />
          }
        ]
      },
      {
        path: 'user',
        children: [
          {
            path: 'list',
            element: <UserList />
          }
        ]
      },
      {
        path: 'meeting',
        children: [
          {
            path: 'list',
            element: <MeetingList />
          }
        ]
      },
      {
        path: 'advisor-request',
        children: [
          {
            path: 'list',
            element: <AdvisorRequestList />
          }
        ]
      }
    ]
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: <Login />
      }
    ]
  },
  {
    path: '*',
    element: <Page404 />
  }
];

export default routes;
