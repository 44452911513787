import React, { useEffect, useMemo } from 'react';
import {
  AppButton,
  AppCheckbox,
  AppEditor,
  AppFile,
  AppInput,
  AppSelect
} from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { t } from 'locales';
import api from 'services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { convertToSlug } from '../../utils/slug';
import { generatePassword } from '../../utils/password';
import { emailPattern } from '../../constants/patterns';

export default function AddDoctor() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const id = searchParams.get('id');
  const isEdit = !!id;
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  const [password] = useState(generatePassword());
  const [image, setImage] = useState();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    watch
  } = useForm({
    defaultValues: useMemo(() => {
      return data;
    }, [data])
  });

  useEffect(() => {
    const country = watch('country');
    if (country) {
      const getCityForCountry = [];
      citiesData.map(city => {
        if (city.country._id === country.value) {
          getCityForCountry.push({
            value: city._id,
            label: city.name[0].value
          });
        }
      });
      setCities(getCityForCountry);
    } else {
      setCities([]);
    }
  }, [watch('country')]);

  useEffect(() => {
    const category = watch('category');
    if (category) {
      const getSubCategoryForCategory = [];
      subCategoriesData.map(subCategory => {
        if (subCategory.category._id === category.value) {
          getSubCategoryForCategory.push({
            value: subCategory._id,
            label: subCategory.name[0].value
          });
        }
      });
      setSubCategories(getSubCategoryForCategory);
    } else {
      setSubCategories([]);
    }
  }, [watch('category')]);

  useEffect(() => {
    const init = async () => {
      const countriesData = [];
      const categoriesData = [];
      const allSubCategories = [];
      const allCities = [];
      await api.shared.list('country', 0).then(async res => {
        res.data.map(country => {
          countriesData.push({
            value: country._id,
            label: country.name[0].value
          });
        });
        setCountries(countriesData);
      });
      await api.shared.list('city', 0).then(async res => {
        res.data.map(city => {
          allCities.push({
            value: city._id,
            label: city.name[0].value
          });
          setCitiesData(prev => [...prev, city]);
        });
      });
      await api.shared.list('category', 0).then(async res => {
        res.data.map(category => {
          categoriesData.push({
            value: category._id,
            label: category.name[0].value
          });
        });
        setCategories(categoriesData);
      });
      await api.shared.list('subCategory', 0).then(async res => {
        res.data.map(subCategory => {
          allSubCategories.push({
            value: subCategory._id,
            label: subCategory.name[0].value
          });
          setSubCategoriesData(prev => [...prev, subCategory]);
        });
      });
      if (isEdit) {
        await api.shared.get('doctor', id).then(async res => {
          const data = res.data;
          const editData = {
            name: data.name,
            email: data.email,
            country: countriesData.find(
              country => country.value === data.country
            ),
            city: allCities.find(city => city.value === data.city),
            category: categoriesData.find(
              category => category.value === data.category
            ),
            subCategory: allSubCategories.filter(subCategory =>
              data.subCategory.includes(subCategory.value)
            ),
            content: data.content,
            age: data.age,
            about: data.about,
            nationality: data.nationality,
            averagePriceList: data.averagePriceList,
            isMostPreferred: data.isMostPreferred,
            isMostCalled: data.isMostCalled,
            rating: data.rating,
            description: data.description
          };
          setImage(data.profileImage);
          setData(editData);
          reset(editData);
        });
      }
    };
    init();
  }, []);

  const onSubmit = async submitData => {
    setIsLoading(true);
    const params = {
      name: submitData.name,
      email: submitData.email,
      slug: convertToSlug(data.name),
      country: submitData.country.value,
      city: submitData.city.value,
      category: submitData.category.value,
      subCategory: submitData.subCategory.map(subCategory => subCategory.value),
      content: submitData.content,
      age: submitData.age,
      about: submitData.about,
      nationality: submitData.nationality,
      profileImage: image,
      averagePriceList: submitData.averagePriceList,
      isMostPreferred: submitData.isMostPreferred,
      isMostCalled: submitData.isMostCalled,
      rating: submitData.rating,
      description: submitData.description
    };

    if (!isEdit) {
      params.password = password;
    }

    await api.shared[isEdit ? 'update' : 'create']('doctor', params, id)
      .then(async () => {
        navigate('/doctor/list');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-3xl font-bold text-gray-700 mb-4 text-center">
        Yeni Doktor Ekle
      </div>
      <div className="text-lg text-gray-700 mb-8 text-center">
        Bu alandan yeni bir doktor ekleyebilirsiniz. Lütfen tüm alanları
        doldurunuz
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppSelect
          options={countries}
          label="Hizmet Verdiği Ülke"
          name="country"
          error={errors.country}
          rules={{ required: true }}
          control={control}
          placeholder="Hizmet Verdiği Ülke"
        />
        <AppSelect
          options={cities}
          label="Şehir"
          name="city"
          error={errors.city}
          rules={{ required: true }}
          control={control}
          placeholder="Şehir"
        />
        <AppInput
          label="Doktor Adı"
          name="name"
          // @ts-ignore
          error={errors.name}
          rules={{ required: true }}
          register={register}
          placeholder="Doktor Adı"
        />
        <AppInput
          label="Doktor E-Posta"
          name="email"
          // @ts-ignore
          error={errors.email}
          rules={{ required: true, pattern: emailPattern }}
          register={register}
          placeholder="Doktor E-Posta"
        />
        <AppInput
          label="Doktor Uyruğu - Ülke Kodu"
          name="nationality"
          // @ts-ignore
          error={errors.nationality}
          rules={{}}
          register={register}
          placeholder="Doktor Uyruğu"
        />
        <div className={'text-xs mb-4'}>
          Ülke eklerken 2 harf kullanınız. Örnek: TR, DE, US.
          <a
            href="https://www.nationsonline.org/oneworld/country_code_list.htm"
            target="_blank"
            rel="noreferrer"
            className="underline ml-1"
          >
            Ülke Kodları için tıklayınız.
          </a>
        </div>
        <AppInput
          label="Doktor Yaşı"
          name="age"
          // @ts-ignore
          error={errors.age}
          rules={{}}
          register={register}
          placeholder="Doktor Yaşı"
        />
        <AppInput
          label="Doktor Kısa Açıklaması"
          name="description"
          // @ts-ignore
          error={errors.description}
          rules={{}}
          register={register}
          placeholder="Doktor Kısa Açıklaması"
        />
        <AppInput
          label="Doktor Puanı"
          name="rating"
          // @ts-ignore
          error={errors.rating}
          rules={{}}
          register={register}
          placeholder="Doktor Puanı"
        />
        <AppSelect
          options={categories}
          label="Kategori"
          name="category"
          error={errors.category}
          rules={{ required: true }}
          control={control}
          placeholder="Kategori"
        />
        <AppSelect
          options={subCategories}
          label="Alt Kategori"
          name="subCategory"
          error={errors.subCategory}
          rules={{ required: true }}
          control={control}
          placeholder="Alt Kategori"
          isMulti={true}
        />
        <AppEditor
          label="Doktor İçeriği"
          name="content"
          // @ts-ignore
          error={errors.content}
          rules={{}}
          control={control}
          placeholder="Doktor İçeriği"
        />
        <AppEditor
          label="Doktor Ek Bilgileri"
          name="about"
          // @ts-ignore
          error={errors.about}
          rules={{}}
          control={control}
          placeholder="Doktor Ek Bilgileri"
        />
        <img src={image} className="w-32 h-auto mt-5 bg-gray-300" />
        <AppFile
          error=""
          label="Profil Resmi"
          onCompleted={file => setImage(file.cdnUrl)}
          crop="1:1"
        />
        <AppEditor
          label="Doktor Fiyat Bilgileri"
          name="averagePriceList"
          // @ts-ignore
          error={errors.averagePriceList}
          rules={{}}
          control={control}
          placeholder="Doktor Fiyat Bilgileri"
        />
        <AppCheckbox
          error={errors.isMostPreferred}
          name="isMostPreferred"
          label={'En Çok Tercih Edilen Doktor Olarak İşaretle'}
          register={register}
        />
        <AppCheckbox
          error={errors.isMostCalled}
          name="isMostCalled"
          label={'En Çok Görüntülü Arama Yapan Doktor Olarak İşaretle'}
          register={register}
        />
        {!isEdit && (
          <div className="flex p-4 rounded-lg border border-gray-300 text-gray-600 gap-1 flex-col">
            <div className="flex gap-1">
              <div className="font-bold">Şifre:</div>
              {password}
            </div>
            <span className="text-sm text-gray-500">
              * Bu şifreyi lütfen kaydedin. Doktor kendi paneli üzerinden bu
              şifreyle ilk girişini yapabilmektedir. Doktor eklendikten sonra bu
              şifre gözükmeyecektir.
            </span>
          </div>
        )}
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          {t('_general.save')}
        </AppButton>
      </form>
    </main>
  );
}
