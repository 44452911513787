import { AppButton, AppInput, AppModal, AppSelect, AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { useForm } from 'react-hook-form';

const DoctorPriceList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [data, setData] = useState([]);
  const [page] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const titles = [
    'DOCTOR',
    'KATEGORİ',
    'Operasyon',
    'MİN. FİYAT',
    'MAX. FİYAT'
  ];
  const [doctor, setDoctor] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoriesData, setSubCategoriesData] = useState([]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    control
  } = useForm({
    defaultValues: {}
  });
  const onDelete = async id => {
    api.shared.delete('priceList', id).then(() => {
      navigate(0);
    });
  };

  useEffect(() => {
    const init = async () => {
      const categoriesData = [];
      const allSubCategories = [];
      await api.shared.list('category', 0).then(async res => {
        res.data.map(category => {
          categoriesData.push({
            value: category._id,
            label: category.name[0].value
          });
        });
        setCategories(categoriesData);
      });
      await api.shared.list('subCategory', 0).then(async res => {
        res.data.map(subCategory => {
          allSubCategories.push({
            value: subCategory._id,
            label: subCategory.name[0].value
          });
          setSubCategoriesData(prev => [...prev, subCategory]);
        });
      });
    };
    init();
  }, []);

  useEffect(() => {
    const category = watch('category');
    if (category) {
      const getSubCategoryForCategory = [];
      subCategoriesData.map(subCategory => {
        if (subCategory.category._id === category.value) {
          getSubCategoryForCategory.push({
            value: subCategory._id,
            label: subCategory.name[0].value
          });
        }
      });
      setSubCategories(getSubCategoryForCategory);
    } else {
      setSubCategories([]);
    }
  }, [watch('category')]);

  const onSubmit = async submitData => {
    setIsLoading(true);

    await api.shared
      .create('priceList', {
        doctorId: id,
        category: submitData.category.value,
        subCategory: submitData.subCategory.value,
        minPrice: submitData.minPrice,
        maxPrice: submitData.maxPrice
      })
      .then(async () => {
        navigate(0);
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  useEffect(() => {
    api.shared
      .listByDoctor('priceList', id)
      .then(async res => {
        setData(res.data);
      })
      .catch(error => console.log('error', error));

    api.shared
      .get('doctor', id)
      .then(async res => {
        setDoctor(res.data);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <AppButton
        className="mb-5 ml-auto"
        isFullWidth={false}
        isAdd={true}
        onClick={() => setIsModalOpen(true)}
      >
        Yeni Ödeme Aralığı Ekle
      </AppButton>
      <AppTable
        titles={titles}
        rows={data}
        isEdit={false}
        isDelete={true}
        pageKey="doctor"
        pageNumber={page}
        onDelete={onDelete}
        isSendArchive={false}
        pageItems={20}
        hideRows="__v,_id,createdAt,updatedAt,isDeleted"
      />
      <AppModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className={`text-center text-2xl font-bold mb-4`}>
          Yeni Ödeme Aralığı Ekle
        </div>
        <div className="flex text-sm text-gray-700 mb-2 gap-2">
          Doktor:
          <span className={`font-bold`}>{doctor.name}</span>
        </div>
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <AppSelect
            options={categories}
            label="Kategori"
            name="category"
            error={errors.category}
            rules={{ required: true }}
            control={control}
            placeholder="Kategori"
          />
          <AppSelect
            options={subCategories}
            label="Alt Kategori"
            name="subCategory"
            error={errors.subCategory}
            rules={{ required: true }}
            control={control}
            placeholder="Alt Kategori"
          />
          <AppInput
            label="Minimum Fiyat (€)"
            name="minPrice"
            // @ts-ignore
            error={errors.minPrice}
            rules={{ required: true }}
            register={register}
            placeholder="Minimum Fiyat"
          />
          <AppInput
            label="Maximum Fiyat (€)"
            name="maxPrice"
            // @ts-ignore
            error={errors.maxPrice}
            rules={{ required: true }}
            register={register}
            placeholder="Maximum Fiyat"
          />
          <AppButton type="submit" className="mt-5" isLoading={isLoading}>
            EKLE
          </AppButton>
        </form>
      </AppModal>
    </main>
  );
};

export default DoctorPriceList;
