import React from 'react';
import { FormErrorMessage } from 'components';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

const AppInput = ({
  label,
  options,
  name,
  error,
  rules,
  control,
  placeholder,
  isMulti = false
}) => {
  return (
    <div className="w-full mb-5">
      <label
        htmlFor={name}
        className={`w-full block mb-2 text-sm font-medium ${
          error ? 'text-red-600' : 'text-gray-900'
        }`}
      >
        {label}
      </label>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => (
          <Select
            {...field}
            isMulti={isMulti}
            options={options}
            placeholder={placeholder}
          />
        )}
      />
      <FormErrorMessage label={label} error={error} />
    </div>
  );
};

export default AppInput;
