import { AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';

const UserList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const titles = ['İSİM', 'E-POSTA', 'ÜYELİK TARİHİ'];

  const onDelete = async id => {
    api.shared.delete('user', id).then(() => {
      navigate(0);
    });
  };

  useEffect(() => {
    api.shared
      .list('user', page)
      .then(async res => {
        setData(res.data);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <AppTable
        titles={titles}
        rows={data}
        isEdit={false}
        isDelete={true}
        pageKey="user"
        pageNumber={page}
        onDelete={onDelete}
        isSendArchive={false}
        pageItems={20}
        hideRows="__v,_id,updatedAt,type"
      />
    </main>
  );
};

export default UserList;
