import React, { useEffect, useMemo } from 'react';
import {
  AppButton,
  AppEditor,
  AppInput,
  AppSelect,
  MaskInput
} from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { t } from 'locales';
import api from 'services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { dateToString, timeToString } from 'utils/date';

export default function AddDoctor() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const id = searchParams.get('id');
  const isEdit = !!id;
  const [doctors, setDoctors] = useState([]);
  const [users, setUsers] = useState([]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return data;
    }, [data])
  });

  useEffect(() => {
    const init = async () => {
      const doctorData = [];
      const userData = [];
      await api.shared.list('doctor', 0).then(async res => {
        res.data.map(doctor => {
          doctorData.push({
            value: doctor._id,
            label: doctor.name
          });
        });
        setDoctors(doctorData);
      });
      await api.shared.list('user', 0).then(async res => {
        res.data.map(user => {
          userData.push({
            value: user._id,
            label: user.name
          });
        });
        setUsers(userData);
      });
      if (isEdit) {
        await api.shared.get('payment', id).then(async res => {
          const data = res.data;
          const editData = {
            name: data.nam,
            doctor: doctorData.find(doctor => doctor.value === data.doctor),
            user: userData.find(user => user.value === data.user),
            paymentTitle: data.paymentTitle,
            paymentSummary: data.paymentSummary,
            hospital: data.hospital,
            price: data.price,
            note: data.note,
            date: dateToString(data.date),
            time: timeToString(data.date)
          };
          setData(editData);
          reset(editData);
        });
      }
    };
    init();
  }, []);

  const onSubmit = async submitData => {
    setIsLoading(true);

    const splitDate = submitData.date.split('/');
    const dateFormat = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
    const operationDate = new Date(`${dateFormat} ${submitData.time}`);

    const params = {
      doctor: submitData.doctor.value,
      user: submitData.user.value,
      paymentTitle: submitData.paymentTitle,
      paymentSummary: submitData.paymentSummary,
      hospital: submitData.hospital,
      price: submitData.price,
      note: submitData.note,
      date: operationDate
    };

    await api.shared[isEdit ? 'update' : 'create']('payment', params, id)
      .then(async () => {
        navigate('/payment/list');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-3xl font-bold text-gray-700 mb-4 text-center">
        Yeni Ödeme Ekle
      </div>
      <div className="text-lg text-gray-700 mb-8 text-center">
        Bu alandan yeni bir ödeme formu ekleyebilirsiniz. Bu formu doldurduktan
        sonra kullanıcının ödeme yap butonu aktif olacaktır. Kullanıcı ödeme
        yaptıktan sonra ödeme tamamlanmış olarak işaretlenecektir.
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppSelect
          options={doctors}
          label="Doktor"
          name="doctor"
          error={errors.doctor}
          rules={{ required: true }}
          control={control}
          placeholder="Doktor"
        />
        <AppSelect
          options={users}
          label="Kullanıcı"
          name="user"
          error={errors.user}
          rules={{ required: true }}
          control={control}
          placeholder="Kullanıcı"
        />
        <AppInput
          label="İşlem Adı"
          name="paymentTitle"
          // @ts-ignore
          error={errors.paymentTitle}
          rules={{}}
          register={register}
          placeholder="İşlem Adı"
        />
        <AppInput
          label="Hastane"
          name="hospital"
          // @ts-ignore
          error={errors.hospital}
          rules={{}}
          register={register}
          placeholder="Hastane"
        />
        <AppInput
          label="İşlem Tutarı (Euro)"
          name="price"
          // @ts-ignore
          error={errors.price}
          rules={{}}
          register={register}
          placeholder="İşlem Tutarı (Euro)"
        />
        <AppEditor
          label="İşlem Özeti"
          name="paymentSummary"
          // @ts-ignore
          error={errors.paymentSummary}
          rules={{}}
          control={control}
          placeholder="İşlem Özeti"
        />
        <AppEditor
          label="Not"
          name="note"
          // @ts-ignore
          error={errors.note}
          rules={{}}
          control={control}
          placeholder="Not"
        />
        <MaskInput
          label="İşlem Tarihi"
          mask="99/99/9999"
          maskPlaceholder="GG/AA/YYYY"
          name="date"
          // @ts-ignore
          error={errors.date}
          register={register}
          placeholder="İşlem Tarihi"
        />
        <MaskInput
          label="İşlem Saati"
          mask="99:99"
          maskPlaceholder="HH:MM"
          name="time"
          // @ts-ignore
          error={errors.time}
          register={register}
          placeholder="İşlem Saati"
        />
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          {t('_general.save')}
        </AppButton>
      </form>
    </main>
  );
}
