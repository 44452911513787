import React from 'react';

const Home = () => {
  return (
    <div>
      <h2 className="mb-2 text-lg font-semibold text-gray-900">
        Admin Kullanım Kılavuzu
      </h2>
      <ul className="space-y-1 list-disc list-inside text-gray-500 ">
        <li>Yakın zamanda kullanım kılavuzu eklenecektir.</li>
      </ul>
    </div>
  );
};

export default Home;
